<template>
  <div class="mt-0" v-if="!showComments">
    <div style=" max-height: 30vh; overflow-y: auto" class="mt-0" :ref="element.id + '-pm-commentBox'">
      <comments :ref="'pm-comments-' + element.id" :post="element" :previewComment="!showComments"
        @updateCommentText="updateCommentText" @commentDeleted="commentDeleted" @scrollToBottom="scrollComments" />
    </div>

    <div class="px-5 mt-2">
      <t-input-group :feedback="
        errors.first(element.id + '-pm-commentText')
          ? errors.first(element.id + '-pm-commentText')
          : ''
      " :variant="errors.first(element.id + '-pm-commentText') ? 'danger' : ''" style="position: relative">

        <t-loader v-if="isLoading" extraClass="border-orange-primary" style="position: absolute; right: 5%; top: 20%" />

        <Suggestions :text.sync="commentText" :inputId="element.id + '-pm-commentText'"
          :taggedUsers.sync="ids_tagged_users" />
      </t-input-group>
    </div>
    <p @click="$emit('toggleComments')" class="cursor-pointer mx-4 my-1 font-bold text-black-500 dark:text-gray-400">
      {{
          allCommentsText(element.total_comments)
      }}
    </p>
  </div>

  <div v-else class="px-5">
    <t-input-group :feedback="
      errors.first(element.id + '-pm-commentText')
        ? errors.first(element.id + '-pm-commentText')
        : ''
    " :variant="errors.first(element.id + '-pm-commentText') ? 'danger' : ''" style="position: relative">
      <!-- <p v-if="!isLoading || showComments" @click="commentText === '' ? null : sendComment(element)"
        style="position: absolute; right: 8%; top: 20%; cursor: pointer" class="select-none"
        :class="commentText === '' ? 'text-gray-500' : 'text-primary-600'">
        {{ $i18n.t("send") }}
      </p> -->
      <t-loader v-if="isLoading" extraClass="border-orange-primary" style="position: absolute; right: 5%; top: 20%" />

      <Suggestions :text.sync="commentText" :inputId="element.id + '-pm-commentText'"
        :taggedUsers.sync="ids_tagged_users" />
      <div style="max-height: 30vh; overflow-y: auto" class="mt-0" :ref="element.id + '-pm-commentBox'">
        <comments :ref="'pm-comments-' + element.id" :post="element" :previewComment="!showComments"
          @updateCommentText="updateCommentText" @commentDeleted="commentDeleted" @scrollToBottom="scrollComments" />
      </div>
      <t-input class="pr-20 mt-1" v-validate="'max:1000'" data-vv-validate-on="change"
        :name="element.id + '-pm-commentText'" :id="element.id + '-pm-commentText'" v-model="commentText"
        :ref="element.id + '-pm-commentText'" :placeholder="$i18n.t('add_comment')"
        v-on:keyup.enter="!isLoading ? sendComment(element) : null" />
    </t-input-group>
  </div>
</template>

<script>
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import Comments from "@/components/post/comments.vue"
import Suggestions from "@/components/user/Suggestions.vue"

export default {
  components: {
    ProfilePicture,
    Comments,
    Suggestions
  },
  props: {
    showComments: {
      type: Boolean,
      default: false
    },
    element: {
      type: Object,
      required: true
    },
    loggedUserID: {
      type: Number,
      required: true
    }
  },
  emits: ['toggleComments'],
  data() {
    return {
      isLoading: false,
      commentText: "",
      ids_tagged_users: []
    }
  },
  computed: {
    OAuthPreferences() {
      return this.$store.getters["auth/getOAuthPreferences"]
    },

  },
  emits: ["commentDeleted"],
  methods: {
    sendComment(post) {
      this.$validator
        .validate(this.element.id + "-pm-commentText")
        .then((isValid) => {
          if (isValid) {
            this.isLoading = true
            let payload = {
              id_user: this.loggedUserID,
              comment: this.commentText,
              id_post: post.id,
              bearer_token:
                this.OAuthPreferences &&
                  this.OAuthPreferences.accessToken &&
                  this.OAuthPreferences.likes_comments
                  ? this.OAuthPreferences.accessToken
                  : null
            }
            if (this.id_comment_reply)
              payload.id_comment_reply = this.id_comment_reply
            if (this.ids_tagged_users.length > 0)
              payload.ids_tagged_users = this.ids_tagged_users

            this.$store
              .dispatch("post/addComment", {
                payload,
                post
              })
              .then((response) => {
                if ([200, 201].includes(response.code)) {
                  this.$refs["pm-comments-" + this.element.id].addComment(
                    response.data
                  )
                  this.commentText = ""
                }
                this.isLoading = false
              })
              .catch((error) => {
                console.log("post comment error:", error)
                notify().then(({ notification }) => {
                  notification(
                    "error",
                    error.code,
                    this.$snotify,
                    this.$i18n.locale
                  )
                })
                this.isLoading = false
              })
          }
        })
    },
    updateCommentText(comment) {
      this.commentText = `@${comment.user.username} `
      this.id_comment_reply = comment.id
      this.ids_tagged_users.push(`@${comment.user.username}`)
      this.$refs[this.element.id + "-pm-commentText"].focus()
    },
    commentDeleted(comment) {
      this.$emit("commentDeleted", comment)
    },
    scrollComments() {
      this.$nextTick(() => {
        this.$refs[this.element.id + "-pm-commentBox"].scrollTo(
          0,
          this.$refs[this.element.id + "-pm-commentBox"].scrollHeight
        )
      })
    },
    allCommentsText(total_comments) {
      if (total_comments <= 3) return ''
      return `${this.$i18n.t('view_all')} ${total_comments} ${this.$i18n.t('comments')} `
    }
  },
  watch: {
    commentText() {
      if (this.commentText === "") {
        if (this.id_comment_reply) {
          // If user resets input after having selected "Respond"
          this.id_comment_reply = null
          this.ids_tagged_users = []
        }
      }
    }
  },
}
</script>

<style>

</style>
