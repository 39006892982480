<template>
  <div v-if="!canSee">
    <div class="flex justify-center items-center">
      <p class="text-lg text-black dark:text-white py-5">
        {{ $i18n.t("post_invalid_country") }}
      </p>
    </div>
  </div>
  <div class="my-16" v-observe-visibility="
    (isVisible, entry) => observeOptions(isVisible, entry, post)
  " v-else>
    <div style="background-color: transparent;">
      <div class="pb-2 px-5">
        <div class="w-full flex justify-between" style="align-items: center;">
          <div class="flex">
            <div class="w-14 h-14">
              <profile-picture :data="user" :sizePx="128" />
            </div>

            <div class="ml-3 my-auto">
              <p class="text-black dark:text-white text-sm sm:text-md" @click="
                loggedUser && loggedUser.id === user.id
                  ? $router.push('/profile')
                  : $router.push(`/user/${user.username}`)
              " style="font-weight: 700; display: flex; align-items: center; cursor: pointer">
                {{ user.username }}
                <span class="ml-1" v-if="user.verified == 1">
                  <img src="/static/user_verified.png" style="
                        display: flex;
                        height: 16px;
                        width: 16px;
                    " />
                  <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                </span>
              </p>
              <p class="text-gray-400 text-sm sm:text-md">
                {{ post.created_at | fromNow }}
              </p>
              <p v-if="post.is_hashtag == 1 && post.hashtag" class="text-gray-400 text-xs">
                {{ $t('because_you_follow') }}
                <span class="ml-1 text-primary-1100 font-bold" style="cursor: pointer;"
                  @click="openPostsByHashtag(post.hashtag)">
                  {{ post.hashtag.name }}
                </span>
              </p>
              <p class="text-gray-400 text-sm sm:text-md"
                v-if="user.id_role === 1 && loggedUser && loggedUser.id_role === 1">
                {{
                    post.country
                      ? post.country[$i18n.locale + "_name"]
                      : ""
                }}
              </p>
            </div>
          </div>
          <!-- <DropDownButton
            :post="post"
            :canEdit="canEdit"
            :sharePollResultsConfirmation="sharePollResultsConfirmation"
          /> -->
          <t-dropdown>
            <div slot="trigger" slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler
              }" class="flex">
              <button
                class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-black dark:text-white bg-transparent rounded-r"
                @mousedown="mousedownHandler" @focus="focusHandler" @blur="blurHandler" @keydown="keydownHandler">
                <icon icon="ellipsis-v" class="my-auto text-black dark:text-white text-xl sm:text-2xl"
                  style="cursor: pointer"></icon>
              </button>
            </div>

            <div slot-scope="{ blurHandler }">
              <button v-if="loggedUser && canEdit"
                class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                role="menuitem" @blur="blurHandler" @click="deletePost">
                <img src="/static/icons/delete.svg" class="mr-1 inline-block primary-color-svg" alt="Delete icon"
                  width="20px" height="20px" />
                {{ $i18n.t("delete") }}
              </button>
              <button v-if="loggedUser && canEdit && post.type === 4 && !post.ended"
                class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                role="menuitem" @blur="blurHandler" @click="endPoll">
                <img src="/static/icons/post_mode.svg" class="mr-1 inline-block width: 20px; height: 20px;"
                  alt="Stop poll icon" width="20px" height="20px" />
                {{ $i18n.t("end_poll") }}
              </button>
              <button v-if="loggedUser && canEdit && post.type === 4"
                class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                role="menuitem" @blur="blurHandler" @click="sharePollResults">
                <icon icon="share-alt" class="text-primary-1100 mr-1" />
                {{ $i18n.t("share_poll_results") }}
              </button>
              <button v-if="loggedUser && !canEdit && allowFollow && loggedUser"
                class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                role="menuitem" @blur="blurHandler" @click="toggleFollow">
                <img v-if="loggedUser && [0, 1, 3].includes(user.followed_by_you_status)" src="/static/icons/follow.svg"
                  class="mr-1 inline-block primary-color-svg" alt="Follow icon" width="20px" height="20px" />

                <img v-if="loggedUser && user.followed_by_you_status === 2" src="/static/icons/unfollow.svg"
                  class="mr-1 inline-block primary-color-svg" alt="Unfollow icon" width="20px" height="20px" />

                <span v-if="!followLoader" class="text-black dark:text-white">{{
                    user.followed_by_you_status === 0
                      ? $i18n.t("follow")
                      : ""
                }}{{
    user.followed_by_you_status === 1
      ? $i18n.t("pending")
      : ""
}}{{
    user.followed_by_you_status === 2
      ? $i18n.t("stop_following")
      : ""
}}{{
    user.followed_by_you_status === 3
      ? $i18n.t("rejected")
      : ""
}}</span>
                <span v-if="followLoader">
                  <t-loader extraClass="border-orange-primary mt-1" />
                </span>
              </button>

              <button v-if="loggedUser && !canEdit"
                class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                role="menuitem" @blur="blurHandler" @click="reportPost">
                <icon icon="exclamation-circle" class="text-primary-1100 mr-1" />
                <span class="text-black dark:text-white">{{
                    $i18n.t("report_post")
                }}</span>
              </button>

              <button v-if="post.user.private == 0"
                class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                role="menuitem" @blur="blurHandler" @click="copyPostLink">
                <span class="text-black dark:text-white">{{
                    $i18n.t("copy_post_link")
                }}</span>
              </button>
            </div>
          </t-dropdown>
        </div>
      </div>

      <!-- Container photos poll -->
      <PollGalleryContainer :element="post" />

      <div v-if="post.type == 3">
        <PostType :element="post" :sizePx="sizePx" :loggedUserID="loggedUser ? loggedUser.id : null"
          @updatePoll="updatePoll" />

        <PostText :element="post" />

        <ActionBar :noAuth="loggedUser ? false : true" :element="post" :allowLike="allowLike"
          :allowComments="allowComments" :allowSubscription="allowSubscription" :OAuthPreferences="OAuthPreferences"
          :isLoadingSubscription="isLoadingSubscription" :calculateHighNumber="calculateHighNumber" @likePost="likePost"
          @openModal="openLikesModal" @toggleComments="toggleComments" @sharePost="$emit('sharePost', post)"
          @subscribeChannel="subscribeChannel"
          :id="loggedUser ? loggedUser.id : null" @openProfile="openProfileFromLikesModal" />
      </div>

      <div v-else>
        <PostType :element="post" :sizePx="sizePx" :loggedUserID="loggedUser ? loggedUser.id : null"
          @updatePoll="updatePoll" />

        <ActionBar :noAuth="loggedUser ? false : true" :element="post" :allowLike="allowLike"
          :allowComments="allowComments" :allowSubscription="allowSubscription" :OAuthPreferences="OAuthPreferences"
          :isLoadingSubscription="isLoadingSubscription" :calculateHighNumber="calculateHighNumber" @likePost="likePost"
          @openModal="openLikesModal" @toggleComments="toggleComments" @sharePost="$emit('sharePost', post)"
          @subscribeChannel="subscribeChannel"
          :id="loggedUser ? loggedUser.id : null" @openProfile="openProfileFromLikesModal" />

        <PostText :element="post" />
      </div>

      <CommentsSection v-if="!$route.path.includes('/post/')" :showComments="showComments" :element="post"
        :loggedUserID="loggedUser ? loggedUser.id : null" @openProfile="openProfile"
        @updateCommentText="updateCommentText" @toggleComments="toggleComments" @commentDeleted="commentDeleted"
        @scrollToBottom="scrollComments" />
    </div>

    <t-modal :header="''" :value="postDeletionConfirmation" @closed="onClosed" variant="md">
      <div class="p-3">
        <p class="mb-1 text-black dark:text-white">
          {{ $i18n.t("post_delete_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="gray" @click="postDeletionConfirmation = false" class="mr-2">
            {{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="deletePost">{{
              $i18n.t("delete")
          }}</t-button>
        </div>
      </template>
    </t-modal>

    <t-modal :header="''" :value="pollCloseConfirmation" @closed="onClosed" variant="md">
      <div class="p-3">
        <p class="mb-1 text-black dark:text-white">
          {{ $i18n.t("poll_close_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="gray" @click="pollCloseConfirmation = false" class="mr-2">
            {{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="endPoll">{{
              $i18n.t("end_poll")
          }}</t-button>
        </div>
      </template>
    </t-modal>

    <t-modal :header="''" :value="sharePollResultsConfirmation" @closed="onClosed" variant="md">
      <div class="p-3">
        <p class="mb-1 text-black dark:text-white">
          {{ $i18n.t("share_results_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="gray" @click="sharePollResultsConfirmation = false" class="mr-2">
            {{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="sharePollResults">{{
              $i18n.t("share_poll_results")
          }}</t-button>
        </div>
      </template>
    </t-modal>

    <LikedByModal :modal="likesModal" :postID="postID" @closeModal="closeLikesModal"
      @openProfile="openProfileFromLikesModal" />
  </div>
</template>

<script>
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import Comments from "@/components/post/comments.vue"
import Suggestions from "@/components/user/Suggestions.vue"
import Likes from "@/components/post/likes.vue"
import LikedByModal from "@/components/post/LikedByModal.vue"
import ActionBar from "@/components/post/ActionBar.vue"
import PollGalleryContainer from "@/components/gallery/PollGalleryContainer.vue"

import CommentsSection from "@/components/post/CommentsSection.vue"
import PostType from "@/components/post/PostType.vue"
import PostText from "@/components/post/PostText.vue"
import DropDownButton from "@/components/post/DropDownButton.vue"
const notify = () => import("@/utils/notify.js")

export default {
  components: {
    ProfilePicture,
    Comments,
    Likes,
    Suggestions,
    LikedByModal,
    ActionBar,
    CommentsSection,
    PostType,
    PostText,
    PollGalleryContainer,
    DropDownButton
  },
  emits: ["updatePoll"],
  props: {
    user: {
      type: Object,
      default: () => { }
    },
    element: {
      type: Object,
      default: {}
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    sizePx: {
      type: Number,
      default: -1
    },
    allowComments: {
      type: Boolean,
      default: true
    },
    allowLike: {
      type: Boolean,
      default: true
    },
    allowSubscription: {
      type: Boolean,
      default: true
    },
    allowFollow: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    likesButtonClasses() {
      if (!this.user) {
        return "px-2 py-1 bg-transparent border border-primary-500 rounded shadow-sm hover:bg-primary-1200"
      }

      if ([0, 2, 3].includes(this.user.followed_by_you_status))
        return "px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200"
      if (this.user.followed_by_you_status === 1)
        return "px-2 py-1 bg-transparent border border-gray-500 rounded shadow-sm hover:bg-gray-500"
    },
    likesTextClasses() {
      if (!this.user) {
        return "text-sm hover:text-white"
      }
      if ([0, 2, 3].includes(this.user.followed_by_you_status))
        return "text-sm text-white"
      if (this.user.followed_by_you_status === 1) return "text-sm text-white"
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    OAuthPreferences() {
      return this.$store.getters["auth/getOAuthPreferences"]
    },
    canSee() {
      if ((this.loggedUser && this.loggedUser.id_role === 1) || this.element.id_country === 0)
        return true

      if (this.loggedUser && this.element.id_country === this.loggedUser.id_country) return true

      return false
    }
  },
  data() {
    return {
      showComments: false,
      commentText: "",
      id_comment_reply: null,
      ids_tagged_users: [],
      postID: 0,
      viewed: false,
      cascadeReplies: false,
      postDeletionConfirmation: false,
      pollCloseConfirmation: false,
      sharePollResultsConfirmation: false,
      followLoader: false,
      isLoading: false,
      isLoadingSubscription: false,
      pollLoading: false,
      followListLoader: false,
      likesModal: false,
      post: {}
    }
  },
  emits: ["commentDeleted", "updatePoll", 'likedPost'],
  methods: {
    updatePoll(data) {
      this.$emit("updatePoll", data)
    },
    scrollComments() {
      this.$nextTick(() => {
        this.$refs[this.element.id + "-pm-commentBox"].scrollTo(
          0,
          this.$refs[this.element.id + "-pm-commentBox"].scrollHeight
        )
      })
    },
    closeLikesModal() {
      this.likesModal = false
      this.postID = 0
    },
    openLikesModal(postID) {
      this.postID = postID
      this.likesModal = true
    },
    updateComment(comment) {
      let index = this.comments.findIndex((x) => x.id === comment.id)
      if (index !== -1) {
        this.comments[index] = comment
      }
    },
    likesListUser(user, index) {
      if (user.followed_by_you_status === 3) return

      // this.listFollowLoading = index
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user_sent: this.loggedUser.id,
            id_user_received: user.id
          },
          route: "/follow_request/save",
          module: null
        })
        .then((response) => {
          let userx = user
          let userData = this.user
          if (response.data.active === 2) {
            userx.followed_by_you_status = 0

            if (this.loggedUser && this.user.id === this.loggedUser.id) {
              userData.following--
              this.$emit("userUpdate", userData)
            }
          } else {
            userx.followed_by_you_status = response.data.status_request
            if (this.loggedUser && this.user.id === this.loggedUser.id) {
              userData.following++
              this.$emit("userUpdate", userData)
            }
          }
          // this.followList[index] = userx
          this.listFollowLoading = null
        })
        .catch((error) => {
          this.listFollowLoading = null
          console.log("list follow error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    picture(element) {
      let url
      if (element.imgkit_url_picture) {
        url = element.imgkit_url_picture
        if (this.sizePx !== -1) url += `?tr=w-${this.sizePx},h-${this.sizePx}`
      } else {
        url = element.url_picture
      }
    },
    //   if (answered)
    //     return this.$snotify.error(
    //       this.$i18n.locale === "en"
    //         ? "You already have a vote for this question"
    //         : "Ya tienes un voto para esta pregunta",
    //       this.$i18n.locale === "en" ? "Error" : "Error",
    //       {
    //         timeout: 5000,
    //         showProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         titleMaxLength: 40,
    //         bodyMaxLength: 100,
    //         position: "rightBottom"
    //       }
    //     )
    //   if (post.ended)
    //     return this.$snotify.error(
    //       this.$i18n.locale === "en"
    //         ? "This poll does not allow new votes"
    //         : "Esta encuesta ya no permite nuevos votos",
    //       this.$i18n.locale === "en"
    //         ? "This poll has been closed"
    //         : "La encuesta fue cerrada",
    //       {
    //         timeout: 5000,
    //         showProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         titleMaxLength: 40,
    //         bodyMaxLength: 100,
    //         position: "rightBottom"
    //       }
    //     )
    //   if (!this.pollLoading) {
    //     this.pollLoading = true
    //     if (!post.answered_poll)
    //       this.$store
    //         .dispatch("global/create", {
    //           payload: {
    //             id_user: this.loggedUser.id,
    //             id_poll: post.id,
    //             id_question: question_id,
    //             id_answer: answer_id
    //           },
    //           route: "/post/poll/answer/save",
    //           module: null
    //         })
    //         .then((response) => {
    //           notify().then(({ notification }) => {
    //             notification(
    //               "poll_answer",
    //               response.code,
    //               this.$snotify,
    //               this.$i18n.locale
    //             )
    //           })
    //           if (response.code === 200) {
    //             this.$emit("updatePoll", response.data)
    //           }
    //           this.pollLoading = false
    //         })
    //         .catch((error) => {
    //           notify().then(({ notification }) => {
    //             notification(
    //               "error",
    //               error.code,
    //               this.$snotify,
    //               this.$i18n.locale
    //             )
    //           })
    //           this.pollLoading = false
    //         })
    //   }
    // },
    reportPost() {
      this.$emit("openPostReport", this.element)
    },
    copyPostLink() {
      let created_at = this.element.created_at.split('-').join('').split(':').join('').split(' ').join('')
      let link = 'https://' + location.hostname + '/share/user/' + this.element.user.username + '/post/' + created_at
      console.log(link)

      navigator.clipboard.writeText(link);

      notify().then(({ notification }) => {
        notification("text_copied", 200, this.$snotify, this.$i18n.locale);
      });
    },
    subscribeChannel() {
      this.isLoadingSubscription = true
      this.$store
        .dispatch("global/create", {
          payload: {
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.subscriptions
                ? this.OAuthPreferences.accessToken
                : null
          },
          route: "/post/subscribe_channel/" + this.element.id
        })
        .then((response) => {
          if (response.code != 500) {
            this.element.subscribed_to_channel = 1
          }

          notify().then(({ notification }) => {
            notification(
              "channel_subscribed",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.isLoadingSubscription = false
          this.$emit("subscribedChannel", response.data)
        })
        .catch((error) => {
          console.log(error)
          this.isLoadingSubscription = false
          notify().then(({ notification }) => {
            notification("error", 500, this.$snotify, this.$i18n.locale)
          })
        })
    },
    handleProcessedTextClick(e) {
      if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.$router.push("/post_by_hashtag/" + response.data[0].name.slice(1))
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.openProfile(response.data[0])
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    commentDeleted(comment) {
      this.$emit("commentDeleted", comment)
    },
    toggleFollow() {
      if (this.user.followed_by_you_status === 3) return
      this.followLoader = true
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user_sent: this.loggedUser.id,
            id_user_received: this.user.id
          },
          route: "/follow_request/save",
          module: null
        })
        .then((response) => {
          this.followLoader = false
          let user = this.user
          if (response.data.active === 2) {
            user.followed_by_you_status = 0
          } else {
            user.followed_by_you_status = response.data.status_request
          }
          this.$emit("userUpdate", { post: this.element, user })
        })
        .catch((error) => {
          console.log("follow error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
          this.followLoader = false
        })
    },
    endPoll() {
      if (!this.pollCloseConfirmation) {
        this.pollCloseConfirmation = true
        return
      }
      this.isLoading = true
      this.$store
        .dispatch("global/update", {
          payload: {
            id: this.element.id,
            ended: 1
          },
          route: "/post/update"
        })
        .then((response) => {
          this.pollCloseConfirmation = false
          notify().then(({ notification }) => {
            notification(
              "poll_end",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.isLoading = false
          this.$emit("endPoll", response.data)
        })
        .catch((error) => {
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    sharePollResults() {
      if (!this.sharePollResultsConfirmation) {
        this.sharePollResultsConfirmation = true
        return
      }
      this.isLoading = true
      this.$store
        .dispatch("global/update", {
          payload: {
            id: this.element.id
          },
          route: "/post/poll/share_results"
        })
        .then((response) => {
          this.sharePollResultsConfirmation = false
          notify().then(({ notification }) => {
            notification(
              "share_poll_results",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.isLoading = false
          this.$emit("endPoll", response.data)
          if (this.$route.path !== "/home") {
            this.$router.push("/home")
          }
        })
        .catch((error) => {
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    switchConfirmation({ confirmation, type }) {
      const options = {
        delete: this.postDeletionConfirmation,
        poll: this.pollCloseConfirmation,
        loading: this.isLoading
      }
      options[type] = confirmation
    },
    deletePost() {
      if (!this.postDeletionConfirmation) {
        this.postDeletionConfirmation = true
        return
      }
      this.$store
        .dispatch("global/changeStatus", {
          payload: { id: this.element.id, status: 2 },
          route: "/post"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification(
              "post-delete",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          if (response.code === 200) this.$emit("postDeleted", this.element)
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    onClosed() {
      this.postDeletionConfirmation = false
      this.pollCloseConfirmation = false
    },
    updateCommentText(comment) {
      this.commentText = `@${comment.user.username} `
      this.id_comment_reply = comment.id
      this.ids_tagged_users.push(`@${comment.user.username}`)
      this.$refs[this.element.id + "-pm-commentText"].focus()
    },
    observeOptions(isVisible, entry, element) {
      return {
        callback: this.visibilityChanged(isVisible, entry, element),
        intersection: {
          threshold: 0.4
        }
      }
    },
    visibilityChanged(isVisible, entry, element) {
      if (!this.viewed) {
        if (isVisible && this.loggedUser) {
          this.viewed = true
          this.$socket.emit("post_viewed", {
            id_user: this.loggedUser.id,
            id_post: element.id
          })
        }
      }
    },
    handleVideoPlay(postId, htmlElement) {
      if (!htmlElement.classList.contains("viewed")) {
        htmlElement.classList.add("viewed")
        this.$store
          .dispatch("global/update", {
            payload: {
              id: postId,
              new_view: 1
            },
            route: "/post/update",
            module: null
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    openProfileFromLikesModal(user) {
      this.likesModal = false
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    openProfile(user) {
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    sendComment(post) {
      this.$validator
        .validate(this.element.id + "-pm-commentText")
        .then((isValid) => {
          if (isValid) {
            this.isLoading = true
            let payload = {
              id_user: this.loggedUser.id,
              comment: this.commentText,
              id_post: post.id
            }
            if (this.id_comment_reply)
              payload.id_comment_reply = this.id_comment_reply
            if (this.ids_tagged_users.length > 0)
              payload.ids_tagged_users = this.ids_tagged_users

            this.$store
              .dispatch("post/addComment", {
                payload: {
                  ...payload,
                  bearer_token:
                    this.OAuthPreferences &&
                      this.OAuthPreferences.accessToken &&
                      this.OAuthPreferences.likes_comments
                      ? this.OAuthPreferences.accessToken
                      : null
                },
                post
              })
              .then((response) => {
                if ([200, 201].includes(response.code)) {
                  this.$refs["pm-comments-" + this.element.id].addComment(
                    response.data
                  )
                  this.commentText = ""
                }
                this.isLoading = false
              })
              .catch((error) => {
                console.log("post comment error:", error)
                notify().then(({ notification }) => {
                  notification(
                    "error",
                    error.code,
                    this.$snotify,
                    this.$i18n.locale
                  )
                })
                this.isLoading = false
              })
          }
        })
    },
    toggleComments() {
      this.showComments = !this.showComments
    },
    likePost(post) {
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            type_child: "post",
            id_child: post.id,
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.likes_comments
                ? this.OAuthPreferences.accessToken
                : null
          },
          route: "/like/save",
          module: null
        })
        .then((res) => { })
        .catch((error) => {
          console.log("post like error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    openPostsByHashtag(hashtag) {
      this.$router.push("/post_by_hashtag/" + hashtag.name.slice(1))
    },

    calculateHighNumber(number) {
      if (isNaN(number)) return 0
      if (Number(number) == 1000000) return "1M"
      if (Number(number) > 1000000) return (number / 1000000).toFixed(2) + "M"
      if (Number(number) == 1000) return "1K"
      if (Number(number) > 1000) return (number / 1000).toFixed(2) + "K"

      return number
    },
  },
  mounted() {
    this.$nextTick(function () {
      if (this.element.type === 2) {
        window.handleVideoPlay = this.handleVideoPlay
        function onPlayerStateChange(event) {
          let postId = event.target.h.id.split("-player-")[1]
          let htmlId = event.target.h.id
          if (event.data == YT.PlayerState.PLAYING) {
            window.handleVideoPlay(
              parseInt(postId),
              document.getElementById(htmlId)
            )
          }
        }

        let postId = this.element.id
        const player = new YT.Player(`video-player-${postId}`, {
          playerVars: {
            origin: window.location.host,
            widget_referrer: window.location.host
          },
          events: {
            onStateChange: onPlayerStateChange
          }
        })
        window["youtube-player-" + postId] = player
      }
    })
  },
  created() {
    this.post = this.element
  },
  watch: {
    element: {
      handler() {
        this.post = this.element

        this.$nextTick(function () {
          if (this.element.type === 2) {
            window.handleVideoPlay = this.handleVideoPlay
            function onPlayerStateChange(event) {
              let postId = event.target.h.id.split("-player-")[1]
              let htmlId = event.target.h.id
              if (event.data == YT.PlayerState.PLAYING) {
                window.handleVideoPlay(
                  parseInt(postId),
                  document.getElementById(htmlId)
                )
              }
            }

            let postId = this.element.id
            const player = new YT.Player(`video-player-${postId}`, {
              playerVars: {
                origin: window.location.host,
                widget_referrer: window.location.host
              },
              events: {
                onStateChange: onPlayerStateChange
              }
            })
            window["youtube-player-" + postId] = player
          }
        })
      }
    },
    commentText() {
      if (this.commentText === "") {
        if (this.id_comment_reply) {
          // If user resets input after having selected "Respond"
          this.id_comment_reply = null
          this.ids_tagged_users = []
        }
      }
    }
  }
}
</script>

<style lang="scss">
// .dynamic-aspect-ratio {
//   aspect-ratio: 16/9;
// }

.dynamic-aspect-ratio {
  aspect-ratio: 1;
}

@media screen and (max-width: 600px) {
  .dynamic-aspect-ratio {
    aspect-ratio: 1;
  }
}
</style>
