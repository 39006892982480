<template>
  <t-dropdown>
    <div
      slot="trigger"
      slot-scope="{
        mousedownHandler,
        focusHandler,
        blurHandler,
        keydownHandler
      }"
      class="flex"
    >
      <button
        class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-black dark:text-white bg-transparent rounded-r"
        @mousedown="mousedownHandler"
        @focus="focusHandler"
        @blur="blurHandler"
        @keydown="keydownHandler"
      >
        <icon
          icon="ellipsis-v"
          class="mr-2 my-auto text-black dark:text-white text-xl sm:text-2xl"
          style="cursor: pointer"
        ></icon>
      </button>
    </div>

    <div slot-scope="{ blurHandler }">
      <button
        v-if="canEdit"
        class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
        role="menuitem"
        @blur="blurHandler"
        @click="deletePost"
      >
        <img
          src="/static/icons/delete.svg"
          class="mr-1 inline-block primary-color-svg"
          alt="Delete icon"
          width="20px"
          height="20px"
        />
        {{ $i18n.t("delete") }}
      </button>
      <button
        v-if="canEdit && element.type === 4 && !element.ended"
        class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
        role="menuitem"
        @blur="blurHandler"
        @click="endPoll"
      >
        <img
          src="/static/icons/post_mode.svg"
          class="mr-1 inline-block width: 20px; height: 20px;"
          alt="Stop poll icon"
          width="20px"
          height="20px"
        />
        {{ $i18n.t("end_poll") }}
      </button>
      <button
        v-if="canEdit && element.type === 4"
        class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
        role="menuitem"
        @blur="blurHandler"
        @click="sharePollResults"
      >
        <icon icon="share-alt" class="text-primary-1100 mr-1" />
        {{ $i18n.t("share_poll_results") }}
      </button>
      <button
        v-if="!canEdit && allowFollow"
        class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
        role="menuitem"
        @blur="blurHandler"
        @click="toggleFollow"
      >
        <img
          v-if="[0, 1, 3].includes(user.followed_by_you_status)"
          src="/static/icons/follow.svg"
          class="mr-1 inline-block primary-color-svg"
          alt="Follow icon"
          width="20px"
          height="20px"
        />

        <img
          v-if="user.followed_by_you_status === 2"
          src="/static/icons/unfollow.svg"
          class="mr-1 inline-block primary-color-svg"
          alt="Unfollow icon"
          width="20px"
          height="20px"
        />

        <span v-if="!followLoader" class="text-black dark:text-white"
          >{{ user.followed_by_you_status === 0 ? $i18n.t("follow") : ""
          }}{{ user.followed_by_you_status === 1 ? $i18n.t("pending") : ""
          }}{{
            user.followed_by_you_status === 2 ? $i18n.t("stop_following") : ""
          }}{{
            user.followed_by_you_status === 3 ? $i18n.t("rejected") : ""
          }}</span
        >
        <span v-if="followLoader">
          <t-loader extraClass="border-orange-primary mt-1"
        /></span>
      </button>

      <button
        v-if="!canEdit"
        class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
        role="menuitem"
        @blur="blurHandler"
        @click="reportPost"
      >
        <icon icon="exclamation-circle" class="text-primary-1100 mr-1" />
        <span class="text-black dark:text-white">{{
          $i18n.t("report_post")
        }}</span>
      </button>
    </div>
  </t-dropdown>
</template>

<script>
export default {
  props: {
    canEdit: {
      type: Boolean,
      default: false
    },
    element: {
      type: Object,
      required: true
    },
    sharePollResultsConfirmation: {
      type: Boolean,
      default: false
    },
    pollCloseConfirmation: {
      type: Boolean,
      default: false
    },
    allowFollow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      postDeletionConfirmation: false,
      pollCloseConfirmation: false,
      isLoading: false
    }
  },
  emits: ["endPoll", "postDeleted", "switchConfirmation"],
  methods: {
    deletePost() {
      if (!this.postDeletionConfirmation) {
        this.$emit("switchConfirmation", { confirmation: true, type: "delete" })
        this.postDeletionConfirmation = true
        return
      }
      this.$store
        .dispatch("global/changeStatus", {
          payload: { id: this.element.id, status: 2 },
          route: "/post"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification(
              "post-delete",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          if (response.code === 200) this.$emit("postDeleted", this.element)
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    onClosed() {
      this.postDeletionConfirmation = false
      this.pollCloseConfirmation = false
    },
    endPoll() {
      if (!this.pollCloseConfirmation) {
        this.$emit("switchConfirmation", { confirmation: true, type: "poll" })
        // this.pollCloseConfirmation = true
        return
      }
      this.$emit("switchConfirmation", { confirmation: true, type: "loading" })
      this.$store
        .dispatch("global/update", {
          payload: {
            id: this.element.id,
            ended: 1
          },
          route: "/post/update"
        })
        .then((response) => {
          this.pollCloseConfirmation = false
          notify().then(({ notification }) => {
            notification(
              "poll_end",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.$emit("switchConfirmation", {
            confirmation: false,
            type: "loading"
          })
          this.$emit("endPoll", response.data)
        })
        .catch((error) => {
          this.$emit("switchConfirmation", {
            confirmation: false,
            type: "loading"
          })
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    sharePollResults() {
      if (!this.sharePollResultsConfirmation) {
        this.sharePollResultsConfirmation = true
        return
      }
      this.isLoading = true
      this.$store
        .dispatch("global/update", {
          payload: {
            id: this.element.id
          },
          route: "/post/poll/share_results"
        })
        .then((response) => {
          this.sharePollResultsConfirmation = false
          notify().then(({ notification }) => {
            notification(
              "share_poll_results",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.isLoading = false
          this.$emit("endPoll", response.data)
          if (this.$route.path !== "/home") {
            this.$router.push("/home")
          }
        })
        .catch((error) => {
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  }
}
</script>

<style></style>
