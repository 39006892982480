<template>
  <div>
    <template v-if="element.type === 4 && element.post_photos.length > 0">
      <div class="flex flex-col">
        <!-- 1 picture case -->

        <div class="mx-auto my-auto" v-if="element.post_photos.length === 1">
          <img v-if="element.post_photos[0].url_picture" :alt="element.text"
            class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
            :ref="'loading-picture-poll-' + element.post_photos[0].id" />
          <img :alt="element.text" v-if="element.post_photos[0].url_picture" style="border-radius: 15px"
            class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio"
            @load="onPollImageLoad(element.post_photos[0].id)" :src="picture(element.post_photos[0])"
            :ref="'poll-' + element.post_photos[0].id" />
        </div>
        <!-- 2 pictures case -->

        <div class="mx-auto w-full flex" v-if="element.post_photos.length === 2">
          <div class="w-1/2 my-auto">
            <img v-if="element.post_photos[0].url_picture" :alt="element.text"
              class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
              :ref="'loading-picture-poll-' + element.post_photos[0].id" />
            <img :alt="element.text" v-if="element.post_photos[0].url_picture" style="border-radius: 15px"
              class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio"
              @load="onPollImageLoad(element.post_photos[0].id)" :src="picture(element.post_photos[0])"
              :ref="'poll-' + element.post_photos[0].id" />
          </div>
          <div class="w-1/2 my-auto">
            <img v-if="element.post_photos[1].url_picture" :alt="element.text"
              class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
              :ref="'loading-picture-poll-' + element.post_photos[1].id" />
            <img :alt="element.text" v-if="element.post_photos[1].url_picture" style="border-radius: 15px"
              class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio"
              @load="onPollImageLoad(element.post_photos[1].id)" :src="picture(element.post_photos[1])"
              :ref="'poll-' + element.post_photos[1].id" />
          </div>
        </div>

        <!-- 3 pictures case -->

        <div class="mx-auto flex" v-if="element.post_photos.length === 3">
          <div class="w-1/3 my-auto">
            <img v-if="element.post_photos[0].url_picture" :alt="element.text"
              class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
              :ref="'loading-picture-poll-' + element.post_photos[0].id" />
            <img :alt="element.text" v-if="element.post_photos[0].url_picture"
              class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
              @load="onPollImageLoad(element.post_photos[0].id)" :src="picture(element.post_photos[0])"
              :ref="'poll-' + element.post_photos[0].id" />
          </div>
          <div class="w-1/3 my-auto">
            <img v-if="element.post_photos[1].url_picture" :alt="element.text"
              class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
              :ref="'loading-picture-poll-' + element.post_photos[1].id" />
            <img :alt="element.text" v-if="element.post_photos[1].url_picture"
              class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
              @load="onPollImageLoad(element.post_photos[1].id)" :src="picture(element.post_photos[1])"
              :ref="'poll-' + element.post_photos[1].id" />
          </div>
          <div class="w-1/3 my-auto">
            <img v-if="element.post_photos[2].url_picture" :alt="element.text"
              class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
              :ref="'loading-picture-poll-' + element.post_photos[2].id" />
            <img :alt="element.text" v-if="element.post_photos[2].url_picture"
              class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
              @load="onPollImageLoad(element.post_photos[2].id)" :src="picture(element.post_photos[2])"
              :ref="'poll-' + element.post_photos[2].id" />
          </div>
        </div>

        <!-- 4 pictures case -->

        <div class="mx-auto w-full" v-if="element.post_photos.length === 4">
          <div class="flex mx-auto">
            <div class="w-1/2 my-auto">
              <img v-if="element.post_photos[0].url_picture" :alt="element.text"
                class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                :ref="'loading-picture-poll-' + element.post_photos[0].id" />
              <img :alt="element.text" v-if="element.post_photos[0].url_picture"
                class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                @load="onPollImageLoad(element.post_photos[0].id)" :src="picture(element.post_photos[0])"
                :ref="'poll-' + element.post_photos[0].id" />
            </div>
            <div class="w-1/2 my-auto">
              <img v-if="element.post_photos[1].url_picture" :alt="element.text"
                class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                :ref="'loading-picture-poll-' + element.post_photos[1].id" />
              <img :alt="element.text" v-if="element.post_photos[1].url_picture"
                class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                @load="onPollImageLoad(element.post_photos[1].id)" :src="picture(element.post_photos[1])"
                :ref="'poll-' + element.post_photos[1].id" />
            </div>
          </div>
          <div class="flex mx-auto">
            <div class="w-1/2 my-auto">
              <img v-if="element.post_photos[2].url_picture" :alt="element.text"
                class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                :ref="'loading-picture-poll-' + element.post_photos[2].id" />
              <img :alt="element.text" v-if="element.post_photos[2].url_picture"
                class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                @load="onPollImageLoad(element.post_photos[2].id)" :src="picture(element.post_photos[2])"
                :ref="'poll-' + element.post_photos[2].id" />
            </div>
            <div class="w-1/2 my-auto">
              <img v-if="element.post_photos[3].url_picture" :alt="element.text"
                class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                :ref="'loading-picture-poll-' + element.post_photos[3].id" />
              <img :alt="element.text" v-if="element.post_photos[3].url_picture"
                class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                @load="onPollImageLoad(element.post_photos[3].id)" :src="picture(element.post_photos[3])"
                :ref="'poll-' + element.post_photos[3].id" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
const options = {
  one: "mx-auto my-auto",
  two: "w-1/2 my-auto",
  three: {}
}
export default {
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  methods: {
    picture(element) {
      let url
      if (element.imgkit_url_picture) {
        url = element.imgkit_url_picture
        if (this.sizePx !== -1) url += `?tr=w-${this.sizePx},h-${this.sizePx}`
      } else {
        url = element.url_picture
      }
      return url
    },
    onPollImageLoad(id) {
      if (this.$refs["loading-picture-poll-" + id])
        this.$refs["loading-picture-poll-" + id].classList.add("hidden")
      if (this.$refs["poll-" + id])
        this.$refs["poll-" + id].classList.remove("hidden")
    }
  }
}
</script>

<style>

</style>
